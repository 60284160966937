<template>
  <div class="container">
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12 pb-0">
        <h5>Pilih Paket :</h5>
      </div>
      <!-- Form -->
      <div class="col-md-8 pt-0">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span class="text-danger d-block"
                  >*Jika Anda membeli paket baru ketika sisa masa berlangganan
                  Anda lebih dari satu bulan, maka akan diakumulasi menjadi satu
                  bulan (sisanya hangus) dan akan dikalkulasikan dengan masa
                  berlangganan paket baru Anda.</span
                >
                <!-- <span class="text-danger d-block">*Jika Anda membeli paket baru ketika sisa masa berlangganan Anda kurang dari satu bulan, maka sisa berlangganan Anda akan dikalkulasikan dengan masa berlangganan paket baru Anda.</span> -->
              </div>
            </div>
            <!-- method section -->
            <div class="row">
              <div
                class="col-md-6 px-2 py-2"
                v-for="(val, i) in method"
                :key="i"
              >
                <div
                  class="card method-card"
                  :id="val.name"
                  @click="setPaymentMethod(val.name)"
                >
                  <div class="card-body py-1 px-3">
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        image-container
                      "
                    >
                      <div
                        style="height: 200px; width: 200px; position: relative"
                      >
                        <img
                          src="
                          https://is3.cloudhost.id/medhop-prod-bucket/tenant-iona-demo/settings/Medhop/logo/2022/09/Medhop-Software-Subscribe.png
                          "
                          class="image"
                        />
                        <!-- <img
                          :src="
                            'https://is3.cloudhost.id/wellmagic-prod-bucket/' +
                            val.url_image
                          "
                          class="image"
                        /> -->
                      </div>
                    </div>
                    <div class="row px-2 pt-1">
                      <div class="col-md-5">
                        <h5 class="font-package">
                          {{ val.name.toUpperCase() }}
                        </h5>
                      </div>
                      <div class="col-md-7 d-flex justify-content-end">
                        <h5>
                          {{
                            "Rp " +
                            parseInt(val.price).toLocaleString("id-ID") +
                            "/bln"
                          }}
                        </h5>
                      </div>
                    </div>

                    <!-- <div class="row justify-content-center">
                      <div class="col-md-8">
                        <button
                          v-b-modal.modal-xl
                          type="button"
                          class="btn btn-orange w-100 my-4"
                          style="border-radius: 10px"
                        >
                          Lihat Detail
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end method section -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="mt-2 row">
                  <div class="col-md-12 pb-0">
                    <span style="font-size: 12px" class="font-weight-bold"
                      >Jumlah Bulan</span
                    >
                  </div>
                  <!-- old methode choosing month -->
                  <!-- <div class="">
                    <button
                      type="button"
                      class="btn btn-info py-1 px-2 text-center"
                      @click="durationSet('minus')"
                    >
                      <i class="fas fa-sm fa-minus px-1"></i>
                    </button>
                    <button type="button" class="btn py-1 px-2 text-center">
                      {{ payment.duration }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-info py-1 px-2 text-center"
                      @click="durationSet('plus')"
                    >
                      <i class="fas fa-sm fa-plus px-1"></i>
                    </button>
                  </div> -->
                  <!-- end old methode choosing month -->

                  <!-- new methode -->
                  <div class="col-md-12 pt-0 pl-4 mt-1">
                    <!-- <b-form-group
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="payment.duration"
                        :options="monthOption"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                        @input="durationSet($event, 'default')"
                      ></b-form-radio-group>
                    </b-form-group> -->

                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="payment.duration"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                      >
                        <b-form-radio
                          v-for="(duration, index) in monthOption"
                          :value="duration.value"
                          :key="index"
                          @change="durationSet('default', index)"
                          >{{ duration.text }}</b-form-radio
                        >
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                  <!-- end new methode -->
                </div>
                <div class="d-flex" v-if="payment.duration > 5">
                  <b-alert
                    show
                    variant="primary"
                    style="border: none"
                    class="p-2 text-center"
                  >
                    <small>
                      <b> Mendapatkan diskon {{payment.discount}}% dan bonus langganan {{payment.bonus}} bulan </b>
                    </small>
                  </b-alert>
                </div>
                <span style="font-size: 12px">Paket Berlangganan</span>
                <div class="d-flex justify-content-between mr-2">
                  <span
                    style="font-size: 15px; max-width: 100px"
                    class="font-weight-bold"
                    >{{ payment.name.toUpperCase() }}</span
                  >
                  <span style="font-size: 15px" class="font-weight-bold">{{
                    parseInt(payment.price).toLocaleString("id-ID")
                  }}</span>
                </div>

                <hr />
                <!-- <span style="font-size: 12px">Pajak</span> -->
                <div class="d-flex justify-content-between mr-2" v-if="payment.discount != 0">
                  <span style="font-size: 12px" class="font-weight-bold"
                    >Diskon {{ payment.discount }}%</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold"
                    >{{ parseInt(payment.discount_value).toLocaleString("id-ID") }}</span
                  >
                </div>
                <div class="d-flex justify-content-between mr-2">
                  <span style="font-size: 12px" class="font-weight-bold"
                    >PPN 11%</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold">{{
                    parseInt(payment.tax_amount).toLocaleString("id-ID")
                  }}</span>
                </div>
                <hr />
                <div class="d-flex justify-content-between mr-2">
                  <span
                    id="ppn"
                    style="font-size: 12px"
                    class="font-weight-bold"
                    >Biaya Layanan</span
                  >
                  <span
                    id="ppnPrice"
                    style="font-size: 12px"
                    class="font-weight-bold"
                    >GRATIS</span
                  >
                </div>
                <div
                  class="d-flex justify-content-between mr-2 mt-2"
                  style="
                    background-color: #efefef;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <span style="font-size: 14px" class="font-weight-bold"
                    >TOTAL</span
                  >
                  <span style="font-size: 14px" class="font-weight-bold">{{
                    parseInt(payment.total_amount).toLocaleString("id-ID")
                  }}</span>
                </div>
                <div class="d-flex justify-content-between mr-2 mt-2">
                  <span style="font-size: 13px" class="font-weight-bold"
                    >Metode Pembayaran</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold">{{
                    payment.payment_method_name
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-2 d-flex justify-content-center">
            <button
              id="checkout"
              class="btn btn-orange"
              @click="checkout"
              style="width: 100%; border-radius: 10px"
              disabled
            >
              Pilih Pembayaran
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-8">
        <div class="card mt-4">
          <div class="card-body p-2 d-flex justify-content-end">
            <button type="button" class="btn btn-danger px-5" @click="back">
              Batal
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <b-modal id="modal-xl" size="xl" title="Detail Langganan">
       <iframe src="demo_iframe.htm" style="border:none;" title="Iframe Example"></iframe> 
    </b-modal> -->
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/settings/Form.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { setting, getSetting } from '@/core/services/jwt.service.js'

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {},
      method: {},
      // data
      payment: {
        id: 0,
        name: "-",
        price: 0,
        duration: 3,
        tax_amount: 0,
        total_amount: 0,
        payment_method_name: "-",
        payment_method_code: "",
        payment_method_category: "",
        discount: "",
        discount_value: "",
        bonus: "",
      },
      duration: 1,
      // Other
      dataLoaded: false,
      monthOption: [
        {
          text: "3 Bulan",
          value: 3,
        },
        {
          text: "6 Bulan",
          value: 6,
        },
        {
          text: "9 Bulan",
          value: 9,
        },
        {
          text: "12 Bulan",
          value: 12,
        },
        {
          text: "24 Bulan",
          value: 24,
        },
      ],
    };
  },

  methods: {
    async getMonthOption() {
      //   this.form = await module.get('settings/' + this.$route.params.id)
      this.monthOption = await saasModule.get("subscribe/time");
      // this.monthOption = this.monthOption.items;
    },

    async get() {
      //   this.form = await module.get('settings/' + this.$route.params.id)
      this.method = await saasModule.get("subscribe/package");
      this.method = this.method.items;
    },

    setPaymentMethod(code) {
      // delete all selected
      Array.from(document.querySelectorAll(".method-card")).forEach(function (
        el
      ) {
        el.classList.remove("method-card-selected");
      });

      // set selected
      let el = document.getElementById(code);
      el.classList.add("method-card-selected");

      // set data
      let find = this.method.find((x) => x.name == code);

      this.payment.id = find.id;
      this.payment.name = find.name;
      this.payment.price = find.price;
      this.payment.after_price = find.price * this.payment.duration;
      this.payment.tax_amount = parseInt((this.payment.after_price * 11) / 100);
      // this.payment.total_amount =
      //   parseInt(this.payment.after_price) + parseInt(this.payment.tax_amount);
      let total_amount =
        parseInt(this.payment.after_price) + parseInt(this.payment.tax_amount);

      let discount = parseInt(total_amount * this.payment.discount) / 100;
      this.payment.discount_value = discount
      this.payment.total_amount = total_amount - discount;

      // set btn checkout not disabled
      document.getElementById("checkout").removeAttribute("disabled");
    },

    durationSet(type, index) {
      if (type == "minus") {
        if (this.payment.duration > 3) {
          this.payment.duration -= 1;
        }
      } else if (type == "plus") {
        this.payment.duration += 1;
      } else {
        let monthOption = this.monthOption[index];
        this.payment.duration;
        let price = this.payment.price * this.payment.duration;
        this.payment.tax_amount = parseInt((price * 11) / 100);
        // this.payment.total_amount = price + this.payment.tax_amount;
        let total_amount = price + this.payment.tax_amount;
        this.payment.discount = monthOption.discount;
        this.payment.bonus = monthOption.bonus_amount;

        let discount = parseInt(total_amount * monthOption.discount) / 100;
        this.payment.discount_value = discount
        this.payment.total_amount = total_amount - discount;

      }
    },

    async checkout() {
      localStorage.setItem("paymentDetailData", JSON.stringify(this.payment));

      this.$router.push("/subscribe/checkout");
    },

    back() {
      this.$router.push("/billings/list");
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Berlangganan", route: "" },
      { title: "Checkout" },
    ]);
    // Get Data
    this.get();
    this.getMonthOption();
  },
};
</script>

<style >
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.image-input-wrapper {
  width: 38px !important;
  height: 38px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.method-card {
  border: 2px solid #dbdbdb !important;
  border-radius: 10px !important;
}

.method-card:hover {
  border: 2px solid #245590 !important;
  background-color: #efefef;
  cursor: pointer;
}

.method-card-selected {
  border: 2px solid #245590 !important;
  background-color: #efefef;
}

.btn-orange {
  background-color: #f47b20 !important;
  color: white;
}
.btn-orange:hover {
  background-color: #f47c20bb !important;
  color: white;
}

.text-orange {
  color: #f47b20 !important;
}

.font-package {
  color: #245590 !important;
  font-weight: bolder;
}
</style>