<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <b-form-group
        id="input-group-company_name"
        label="Nama Perusahaan:"
        label-for="input-company_name"
      >
        <b-form-input
          id="input-company_name"
          v-model="form.company_name"
          placeholder="Nama Perusahaan"
        ></b-form-input>
        <small class="text-danger">{{ error.company_name }}</small>
      </b-form-group>
      <div class="row" v-if="tenantConfig.subscribe_package_id != 4">
        <div class="col-12">
          <fieldset style="border: 1px solid #dee2e6; padding: 8px">
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Klinik
            </legend>
            <!-- <div class="row my-1">
            <div class="col">
              <b-form-group
                id="input-group-clinic_name"
                label="Nama Klinik:"
                label-for="input-clinic_name"
              >
                <b-form-input
                  id="input-clinic_name"
                  v-model="form.clinic_name"
                  placeholder="Nama Klinik"
                ></b-form-input>
                <small class="text-danger">{{ error.clinic_name }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-clinic_address">
                <label for="input-clinic_address">Alamat Klinik:</label>
                <b-form-textarea
                  id="input-clinic_address"
                  v-model="form.clinic_address"
                  placeholder="Alamat Klinik"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.clinic_address }}</small>
              </b-form-group>
            </div>
          </div> -->
            <b-form-group
              id="input-group-clinic_name"
              label="Nama:"
              label-for="input-clinic_name"
            >
              <b-form-input
                id="input-clinic_name"
                v-model="form.clinic_name"
                placeholder="Nama"
              ></b-form-input>
              <small class="text-danger">{{ error.clinic_name }}</small>
            </b-form-group>

            <b-form-group id="input-group-clinic_address">
              <label for="input-clinic_address">Alamat:</label>
              <b-form-textarea
                id="input-clinic_address"
                v-model="form.clinic_address"
                placeholder="Alamat"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.clinic_address }}</small>
            </b-form-group>

            <div class="row">
              <div class="col">
                <b-form-group
                  id="input-group-phone"
                  label="Telepon:"
                  label-for="input-phone"
                >
                  <b-form-input
                    id="input-phone"
                    v-model="form.phone"
                    placeholder="Telepon"
                  ></b-form-input>
                  <small class="text-danger">{{ error.phone }}</small>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  id="input-group-mobile_phone"
                  label="Telepon Selular:"
                  label-for="input-mobile_phone"
                >
                  <b-form-input
                    id="input-mobile_phone"
                    v-model="form.mobile_phone"
                    placeholder="Telepon Selular"
                  ></b-form-input>
                  <small class="text-danger">{{ error.mobile_phone }}</small>
                </b-form-group>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row" :class="tenantConfig.subscribe_package_id != 4 ? 'mt-7' : ''">
        <div class="col-12">
          <fieldset style="border: 1px solid #dee2e6; padding: 8px">
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Apotek
            </legend>

            <b-form-group id="input-group-pharmacy_name">
              <label for="input-pharmacy-name"
                >Nama: <em class="text-muted">opsional</em></label
              >
              <b-form-input
                id="input-pharmacy_name"
                v-model="form.pharmacy_name"
                placeholder="Nama"
              ></b-form-input>
              <small class="text-danger">{{ error.pharmacy_name }}</small>
            </b-form-group>

            <b-form-group id="input-group-pharmacy_address">
              <label for="input-pharmacy_address"
                >Alamat: <em class="text-muted">opsional</em></label
              >
              <b-form-textarea
                id="input-pharmacy_address"
                v-model="form.pharmacy_address"
                placeholder="Alamat"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.pharmacy_address }}</small>
            </b-form-group>

            <div class="row">
              <div class="col">
                <b-form-group id="input-group-pharmacy_phone">
                  <label for="input-pharmacy_phone"
                    >Telepon: <em class="text-muted">opsional</em></label
                  >
                  <b-form-input
                    id="input-pharmacy_phone"
                    v-model="form.pharmacy_phone"
                    placeholder="Telepon"
                  ></b-form-input>
                  <small class="text-danger">{{ error.pharmacy_phone }}</small>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group id="input-group-pharmacy_mobile_phone">
                  <label for="input-pharmacy_mobile_phone"
                    >Telepon Seluler:
                    <em class="text-muted">opsional</em></label
                  >
                  <b-form-input
                    id="input-pharmacy_mobile_phone"
                    v-model="form.pharmacy_mobile_phone"
                    placeholder="Telepon Selular"
                  ></b-form-input>
                  <small class="text-danger">{{
                    error.pharmacy_mobile_phone
                  }}</small>
                </b-form-group>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row mt-7">
        <div class="col-12">
          <fieldset style="border: 1px solid #dee2e6; padding: 8px">
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Aplikasi
            </legend>
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Konfirmasi Status Perjanjian Otomatis:">
                  <b-form-checkbox
                    switch
                    v-model="form.appointment_confirmation"
                  >
                    {{
                      form.appointment_confirmation ? "Aktif" : "Tidak Aktif"
                    }}
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <b-form-group
                  label="Daftar Status Resep:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.prescription_status_active"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2"
                  >
                    <b-form-checkbox :value="0"
                      >Internal Klinik</b-form-checkbox
                    >
                    <b-form-checkbox :value="1"
                      >Internal Apotek</b-form-checkbox
                    >
                    <b-form-checkbox :value="2">Eksternal</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <b-form-group id="input-group-expired_service">
                  <label for="input-expired_service"
                    >Durasi Expired Deposit Layanan:
                    <em class="text-muted">(default 30 hari)</em></label
                  >
                  <b-form-input
                    id="input-expired_service"
                    v-model="form.expired_service"
                    placeholder="Telepon"
                  ></b-form-input>
                  <small class="text-danger">{{ error.expired_service }}</small>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label for="">Logo:</label>
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedLogoUrl == '' ? form.logo : uploadedLogoUrl,
                      ]"
                    />
                  </div>
                </div>
                <b-form-file
                  v-model="form.logo"
                  @change="onLogoUpload"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  class="mt-3"
                  plain
                ></b-form-file>
              </div>
              <div class="col-4">
                <label for="">Logo Menu:</label>
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedBrandLogoUrl == ''
                          ? form.brand_logo
                          : uploadedBrandLogoUrl,
                      ]"
                    />
                  </div>
                </div>
                <b-form-file
                  v-model="form.brand_logo"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  @change="onBrandLogoUpload"
                  class="mt-3"
                  plain
                ></b-form-file>
              </div>
              <div class="col-4">
                <label for=""
                  >Cover Halaman Login:
                  <em class="text-muted">(450px X 611px)</em></label
                >
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedCoverImageUrl == ''
                          ? form.cover_image
                          : uploadedCoverImageUrl,
                      ]"
                    />
                  </div>
                </div>
                <b-form-file
                  v-model="form.cover_image"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  @change="onCoverImageUpload"
                  class="mt-3"
                  plain
                ></b-form-file>
              </div>
            </div>

            <!-- <div class="row align-items-center">
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedLogoUrl == '' ? form.logo : uploadedLogoUrl,
                      ]"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="input-image"
                  >Logo: <em class="text-muted">(280px X 155px)</em>
                </label>
                <b-form-file
                  v-model="form.logo"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  @change="onLogoUpload"
                ></b-form-file>
                <small class="text-danger">{{ error.logo }}</small>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedBrandLogoUrl == ''
                          ? form.brand_logo
                          : uploadedBrandLogoUrl,
                      ]"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="input-image"
                  >Logo Menu: <em class="text-muted">(180px X 71px)</em>
                </label>
                <b-form-file
                  v-model="form.brand_logo"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  @change="onBrandLogoUpload"
                ></b-form-file>
                <small class="text-danger">{{ error.brand_logo }}</small>
              </div>
            </div>

            <div class="row align-items-center mb-3">
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 200px; width: 200px; position: relative">
                    <img
                      class="image"
                      :src="[
                        uploadedCoverImageUrl == ''
                          ? form.cover_image
                          : uploadedCoverImageUrl,
                      ]"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="input-image"
                  >Cover Halaman Login:
                  <em class="text-muted">(450px X 611px)</em>
                </label>
                <b-form-file
                  v-model="form.cover_image"
                  placeholder="Upload Logo"
                  drop-placeholder="Drop file here..."
                  @change="onCoverImageUpload"
                ></b-form-file>
                <small class="text-danger">{{ error.cover_image }}</small>
              </div>
            </div> -->
          </fieldset>
        </div>
      </div>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary" class="mt-3" v-if="btnAccess"
        >Simpan</b-button
      >
      <!-- <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/action-types')"
      >
        Batal
      </b-button> -->
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import JwtService from "@/core/services/jwt.service";
import { setConfiguration } from "@/core/services/jwt.service.js";
import { getUser } from "@/core/services/jwt.service.js";
import { getTenantConfig } from "@/core/services/jwt.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        logo: "",
        brand_logo: "",
        cover_image: "",
        clinic_name: "",
        clinic_address: "",
        phone: "",
        mobile_phone: "",
        pharmacy_name: "",
        pharmacy_address: "",
        pharmacy_phone: "",
        pharmacy_mobile_phone: "",
        appointment_confirmation: "",
        prescription_status_active: "",
      },
      uploadedLogoUrl: "",
      uploadedBrandLogoUrl: "",
      uploadedCoverImageUrl: "",
      btnAccess: false,
      tenantConfig: getTenantConfig(),
    };
  },

  methods: {
    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2902") {
          this.btnAccess = true;
        }
      }
    },

    onLogoUpload(evt) {
      this.uploadedLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.logo = evt.target.files[0];
    },

    onBrandLogoUpload(evt) {
      this.uploadedBrandLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.brand_logo = evt.target.files[0];
    },

    onBrandLogoUpload(evt) {
      this.uploadedBrandLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.brand_logo = evt.target.files[0];
    },

    onCoverImageUpload(evt) {
      this.uploadedCoverImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.cover_image = evt.target.files[0];
    },

    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      // If Data Found
      if (response != null) {
        setConfiguration(JSON.stringify(response));
        this.$root.$emit("configurationEditSuccessfully");
      }
    },

    async formOnsubmit() {
      this.form.prescription_status_active = JSON.stringify(
        this.form.prescription_status_active
      );

      if (this.form.appointment_confirmation) {
        this.form.appointment_confirmation = 1;
      } else {
        this.form.appointment_confirmation = 0;
      }
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        this.form.prescription_status_active = JSON.parse(
          this.form.prescription_status_active
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // this.$router.push("/masters/action-types");

        this.form.prescription_status_active = JSON.parse(
          this.form.prescription_status_active
        );

        if (this.form.appointment_confirmation) {
          this.form.appointment_confirmation = true;
        } else {
          this.form.appointment_confirmation = false;
        }

        // JwtService.setConfiguration(
        //   JSON.stringify(response.success.item.original)
        // );
        this.getSetting();
      }
    },
  },

  mounted() {
    this.setActiveMenu();
  },
};
</script>

<style>
</style>